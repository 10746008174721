

.end-in {
  display: none

  @media screen and (min-width: 375px) and (max-width: 600px) {
	display: block
	font-size: 1rem
	font-weight: bold
	padding-right: 0.5rem
  }
}

.timing-row {
    display: block
    position: relative
    @media screen and (max-width: 600px) {
        &:not(.is-studio7) {
            display: flex
            justify-content: center
            position: absolute
            top: 80px
            left: 0
            width: 100dvw
            z-index: 9
            padding: 0.8rem 1rem 0.5rem 1rem

            box-shadow: 0px 1px 3px rgba(0,0,0,0.161)
            background: white
            }
    }
}

.timing-row.is-studio7:not(.is-pdp) {
    display: block
    position: relative
    @media screen and (max-width: 600px) {
        display: flex
        position: unset
        width: 100%
        z-index: 9
    }

    .label-timer-light {
        background: transparent
        > div:first-child {
            color: $color-timer-primary !important
        }
    }
}

.campaign-list {
	.timing-row {
		display: block !important
		position: relative !important
		top: unset
		left: unset
		background: unset

		.end-in {
			display: none !important
		}

		.label-timer-dark {
			background: $color-bnn-gray-medium !important
			color: white !important
		}
	}
}

.text-black {
     color: $color-bnn-gray-medium
}

.text-white {
     color: white
}

.label-timer-dark {
    background: $color-bnn-gray-medium
    color: white
    font-size: 32px
    border-radius: 8px
    width: 56px
    height: 56px
    margin auto
    font-weight bold
    display: flex
    justify-content: center
    align-items: center
    flex-wrap: wrap
    flex-direction: column
	@media screen and (min-width: 768px) {
        font-size: 36px
    }
        // @media screen and (max-width: 600px) {
		// background: white
		// color: $color-bnn-gray-medium
	//}
}

.label-timer-light {
    background: white
    color: $color-bnn-gray-medium
    font-size: 32px
    border-radius: 8px
    width: 56px
    height: 56px
    margin auto
    font-weight bold
    display: grid
    grid-template-columns: repeat(1, 1fr)
    justify-content: center
    align-items: center
    flex-wrap: wrap
    @media screen and (min-width: 768px) {
        font-size: 36px
    }
}

.is-studio7:not(.is-pdp) {
    .label-timer-light {
        background: white
        color: $color-bnn-gray-medium
        font-size: 32px
        border-radius: 8px
        width: 56px
        height: fit-content
        margin auto
        font-weight bold
        display: grid
        grid-template-columns: repeat(1, 1fr)
        justify-content: center
        align-items: center
        flex-wrap: wrap
        @media screen and (min-width: 768px) {
            font-size: 36px
        }
    }
}

.is-studio7.is-pdp {
    .label-timer-dark {
        background: white
        color: $color-bnn-gray-medium
        font-size: 24px
        border-radius: 8px
        width: 56px
        height: 56px
        margin auto
        font-weight bold
        display: grid
        grid-template-columns: repeat(1, 1fr)
        justify-content: center
        align-items: center
        flex-wrap: wrap
        @media screen and (min-width: 768px) {
            font-size: 24px
        }
    }

    .label-timer-light {
        background: white
        color: $color-bnn-gray-medium
        font-size: 24px
        border-radius: 8px
        width: 56px
        height: 56px
        margin auto
        font-weight bold
        display: grid
        grid-template-columns: repeat(1, 1fr)
        justify-content: center
        align-items: center
        flex-wrap: wrap
        @media screen and (min-width: 768px) {
            font-size: 24px
        }
    }
}

.timer-layout {
    display: flex
    justify-content: center
    align-items: center
    text-overflow: ellipsis;
    white-space: nowrap;
    gap: 12px
}

.colon {
    margin-top : -20px
    padding 0 1rem
    font-size: 2rem
    font-weight bold
    color: black

    @media screen and (max-width: 600px) {
        font-size: 1.2rem
        padding 0 0.75rem
    }
}

.label-unit {
  font-size: 1rem
  padding-top: 8px

  @media screen and (max-width: 600px) {
    font-size: 0.875rem
	padding-top: 0
  }

}
